import { Menu } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { MAIN_ASSET_QUERY_KEY } from '../../assets/hooks/useAssetsQuery';
import { WORKFLOWS_QUERY_MAIN_KEY } from '../../workflows/components/table/query/useWorkflowsQuery';
import { useGrowthbookContext } from '../../contexts/GrowthbookContext';
import { IMPERSONATION_MAIN_QUERY_KEY, useImpersonationQuery } from '../../../shared/useImpersonationQuery';
import { MAIN_PUBLISHED_QUERY_KEY } from '../../published/hooks/usePublishedQuery';
import { canImpersonate } from '../../../utils/canImpersonate';
import { USER_QUERY_KEY, useUserQuery } from '@/shared/hooks/useUserQuery';
import { USER_OR_IMPERSONATED_USER_QUERY_KEY } from '@/app/settings/hooks/useUserOrImpersonatedUserQuery';
import Cookies from 'js-cookie';
import { IMPERSONATION_COOKIE_NAME } from '@/utils/getImpersonation';

export const ImpersonationMenuItem = ({ open }: { open: () => void }) => {
  const queryClient = useQueryClient();
  const growthbook = useGrowthbookContext();
  const { data: user } = useUserQuery();
  const isImpersonationAvailable = canImpersonate(user);

  const { data: impersonation } = useImpersonationQuery();

  const endImpersonationAction = () => {
    Cookies.remove(IMPERSONATION_COOKIE_NAME);
    queryClient.invalidateQueries({
      predicate: (query) =>
        [
          MAIN_ASSET_QUERY_KEY,
          WORKFLOWS_QUERY_MAIN_KEY,
          MAIN_PUBLISHED_QUERY_KEY,
          IMPERSONATION_MAIN_QUERY_KEY,
          USER_QUERY_KEY,
          USER_OR_IMPERSONATED_USER_QUERY_KEY,
        ].some((key) => query.queryKey.includes(key)),
    });
  };

  const isImpersonateOn = growthbook?.isOn('impersonate-user');

  if (!isImpersonateOn || !isImpersonationAvailable) {
    return null;
  }

  return impersonation?.isImpersonating ? (
    <Menu.Item onClick={() => endImpersonationAction()}>End Impersonation</Menu.Item>
  ) : (
    <Menu.Item onClick={open}>Impersonate User</Menu.Item>
  );
};
