'use client';

import { PropsWithChildren, useState } from 'react';
import { SessionProvider } from 'next-auth/react';
import { MantineProvider } from '@mantine/core';
import { mantineThemeOverride } from '@/app/theme';
import { FileDownloadProvider } from '@/app/components/shared/fileDownload';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Notifications } from '@mantine/notifications';
import { ConfirmationModalProvider } from '@/app/components/modal/ConfirmationModalContext';
import { AssetsUploadContextProvider } from '@/app/assets/components/upload/contexts/useAssetsUploadContext';
import { GrowthbookProvider } from './contexts/GrowthbookContext';
import { CreateSupportTicketProvider } from './contexts/CreateSupportTicketContext';
import { ShowNewPresetsProvider } from './contexts/ShowNewPresetsContext';
import { OnboardingProvider } from './contexts/OnboardingContext/OnboardingContext';

export default function Providers({ children }: PropsWithChildren) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            throwOnError: true,
          },
        },
      })
  );

  return (
    <MantineProvider theme={mantineThemeOverride}>
      {/* for some reason some styles are not applies, let's invistigate it after release */}
      <Notifications
        styles={{
          root: {
            width: 'calc(100% - var(--mantine-spacing-md) * 2)',
            position: 'fixed',
            zIndex: 10000000,
            top: 'var(--notifications-top)',
            left: 'var(--notifications-left)',
            right: 'var(--notifications-right)',
            bottom: 'var(--notifications-bottom)',
            transform: 'var(--notifications-transform)',
            maxWidth: 'var(--notifications-container-width)',
          },
          notification: {
            marginTop: 'var(--mantine-spacing-md)',
          },
        }}
      />
      <SessionProvider>
        <GrowthbookProvider>
          <FileDownloadProvider>
            <QueryClientProvider client={queryClient}>
              <OnboardingProvider>
                <AssetsUploadContextProvider>
                  <CreateSupportTicketProvider>
                    <ConfirmationModalProvider>
                      <ShowNewPresetsProvider>{children}</ShowNewPresetsProvider>
                    </ConfirmationModalProvider>
                  </CreateSupportTicketProvider>
                </AssetsUploadContextProvider>
              </OnboardingProvider>
            </QueryClientProvider>
          </FileDownloadProvider>
        </GrowthbookProvider>
      </SessionProvider>
    </MantineProvider>
  );
}
