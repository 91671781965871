import { getImpersonation } from '@/utils/getImpersonation';
import { useQuery } from '@tanstack/react-query';

export const IMPERSONATION_MAIN_QUERY_KEY = 'impersonation';

//This no longer needs to be a query, as it is now a client-side only operation. Feel free to refactor usages.
export const useImpersonationQuery = () => {
  return useQuery({
    queryKey: [IMPERSONATION_MAIN_QUERY_KEY],
    queryFn: () => getImpersonation(),
    throwOnError: false,
  });
};
