import { getUser, getUserWithImpersonation } from '@/app/(actions)/user/getUser';
import { getImpersonation } from '@/utils/getImpersonation';
import { useQuery } from '@tanstack/react-query';

export const USER_OR_IMPERSONATED_USER_QUERY_KEY = 'userOrImpersonatedUser';

export const useUserOrImpersonatedUserQuery = () =>
  useQuery({
    queryKey: [USER_OR_IMPERSONATED_USER_QUERY_KEY],
    queryFn: async () => {
      const { email, isImpersonating } = getImpersonation();
      const user = await (isImpersonating ? getUserWithImpersonation(email) : getUser());
      return { user, isImpersonating };
    },
    throwOnError: false,
  });
